
body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Montserrat';
    color: #816254;
    margin-bottom: 100px;
}

.container {max-width: 992px; margin: auto; padding: 10px;box-sizing: border-box;}

.design {display: flex;flex-direction: column;}

.btn {
    width: 260px;
    height: 50px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Montserrat';
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.btn-blue {background-color: #2B3153;color: #fff;}
.btn-brown {background-color: #816254;color: #fff;}

.btn-cart {cursor: pointer; border-radius: 100px;box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);width: 80px;height: 80px;}

.H1 {font-size: 32px;margin: 0;padding: 0;}
.H2 {font-size: 64px;margin: 0;padding: 0;}
.H3 {font-size: 24px;margin: 0;padding: 0;}
.subtitle {font-size: 32px;}
.subtitle-2 {font-size: 20px;}

.label {position: relative;display: block;margin-top: 30px}
.label__textarea {margin-top: 40px;}
.input-label {position:absolute;font-size: 24px;top: 20px;left:20px;transform: translateY(-50%);transition: .2s;user-select: none;}
.input-textarea-label {top: 5px}
.input-label.active {transform: translateY(-200%); font-size: 16px}
.input-label__blue {color: #2B3153}
.input-label__brown {color: #816254}
.input {
    font-weight: 700;
    font-family: 'Montserrat';
    cursor: pointer;
    width: 400px;
    max-width: 100%;
    font-size: 24px;
    height: 50px;
    padding: 5px 20px;
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: #FBFBFB;
    border-radius: 10px;
}
.input-textarea {height: 160px;resize: none;}
.input-blue {border-bottom: #2B3153 solid 3px;color: #2B3153;}
.input-brown {border-bottom: #816254 solid 3px;color: #816254;}
form button {margin-top: 20px;}

.headline-description {margin-top: 20px;}

.left-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.headline {
    padding: 10px;
    margin: auto;
}

.headline .btn {
    margin: auto;
    margin-top: 40px;
}

.headline .headline-count-products {margin-top: 40px;}

.headline-list {margin-top: 20px;display: flex;flex-direction: column;}

.right-side {
    width: 50%;
    height: 100%;
    z-index: -1;
}
#section-header {
    display: flex;
    flex-direction: row;
    height: 730px;
}
#section-header .right-side {
    background-image: url('../public/img/header_bg.jpg');
    background-position: center;
}

.headline {display: flex;flex-direction: column;}
.headline-description {display: flex;flex-direction: column;margin-top: 50px;}

.headline-count-products {display: flex;flex-direction: row;justify-content: center;align-items: center;}
.headline-count-products__caption {font-weight: 700;font-size: 40px;}
.headline-count-products__count {
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 90px;
    border-radius: 10px;
    border: solid #816254 3px;
}
.headline-count-products__count span {font-size: 40px;font-weight: 700;}


/* About */
.about-block {display: flex;flex-direction: row;}
.about-block-inner {width: 50%;height:450px;display: flex;justify-content: center;align-items: center;;}
.about-block__reverse {flex-direction: row-reverse;}
.about-block__text-wrapper {
    padding: 10px;
    display: flex;
    margin: 50px auto;
    flex-direction: column;
    max-width: 500px;
}

.about-block-image {width: 100%;height: 100%;}
.color-image {background-image: url(../public/img/color_image.svg);background-repeat: no-repeat;background-position: center center;background-color: #F4D6DB;}
.material-image {background-image: url(../public/img/material_image.jpg); background-repeat: no-repeat; background-position: center center;}
.sizes-image {background-image: url(../public/img/sizes_image.jpg); background-repeat: no-repeat; background-position: center center;}
ul {
    list-style-type: none;
    padding-left: 0;
}
/* Products */
.product {position: relative;}
.products-headline {width: 100%;}
.products-headline-inner {
    text-align: center;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
}
.icon {display: inline;margin-right: 10px;}
.icon-cart {background-image: url(../public/img/product_cart.svg); background-repeat: no-repeat;width: 25px;height: 25px;}
.btn__add-to-cart {width: 200px;box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);white-space: nowrap;}

.products-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
}
.product-image {
    max-width: 100%;
}

.product-info {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #2B3153;
}
.product-info-available {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #2B3153;
    color: #fff;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.product-info-price {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-align: right;
}

.old-price {font-size: 14px;text-decoration: line-through;white-space: nowrap;}
.price {font-size: 32px;white-space: nowrap;}

#section-product {max-width: 100%}
.product-info-right {display: flex;flex-direction: row;flex-wrap: nowrap;align-items: center;width: auto;justify-content: space-between;}
.product-notification {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: auto;
    text-align: center;
    background-color: #2B3153;
    color: #fff;
    padding: 50px 5px;
}
.product-notitfication-price {display: flex;flex-direction: column;}
.product-notification-title {
    font-size: 36px;
    font-weight: 700;
}
.btn__product-notification {
    background-color: #fff;
    color: #2B3153;
}
.product-notification .headline-count-products__count {
    color: #fff;
}
.product-notitfication-price {font-size: 30px}
.product-notification .headline-count-products__caption {font-size: 20px;}

.product-notitfication-price span {margin-top: 10px;}


/* Deffects */
#section-deffects {
    margin-top: 100px;
}
.deffect-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    margin: auto;
    width: 992px;
    grid-gap: 20px;
}
.deffect-gallery-item {
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
    margin-bottom:10px;
    border-radius: 10px;
}
.deffect-gallery-item img {
    width: 100%;
    min-width: 90%;
    height: 100%;
    border-radius: 10px;
}

/* Questions */
#section-question {
    margin-top: 100px;
}
.chats-link {
    display: flex;
    color: #816254;
    margin-top: 20px;
}
.chats-link-text {
    margin-left: 10px;
}

.chats-link-icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chats-link-icon.telegram {
    background-image: url(../public/img/telegram.svg);
}
.chats-link-icon.whatsapp {
    background-image: url(../public/img/whatsapp.svg);
}
.question-item {
    margin-top: 10px;
    box-sizing: border-box;
    max-width: 100%;
    width: 400px;
    overflow: hidden;
    padding: 20px;
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse;
}

.question-item-title {
    cursor: pointer;
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #F6E6DB;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.question-item-title-text {
    margin-left: 20px;
    font-size: 32px;
}

.question-item-content {
    padding: 50px 10px 5px 10px;
    margin-top: -50px;

    /* box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3); */
    border-radius: 10px;
    animation: top-to-bottom both .4s;
}

.question-item-content a {
    color: #816254;
    font-weight: 700;
}

.question-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.question-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Cart button */
.btn-cart-wrapper {
    position: fixed;
    top: 20px; right: 20px;
    z-index: 999;
}

.cart-wrapper h2 {
    padding: 100px 20px 20px 20px;
    margin: 0;
    text-align: center;
    color: #c52525;
}

/* modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: auto;
}
.modal-inner {
    position: relative;
    background-color: #fff;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
    max-height: 100vh;
    overflow: auto;
}

.btn-modal-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    cursor: pointer;
}

.modal-title {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 32px;
    color: #2B3153;
}
/* cart */
.modal__cart .modal-inner {
    width: 100%;
    max-width: 400px;
}
.cart-wrapper {
    margin-top: 100px;
}
/* cart item */
.cart-item {
    width: 100%;
    max-width: 100%;
    position: relative;
    color: #2B3153;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-top: 10px;
    background-color: #D0D3E7;
    padding: 10px;
    box-sizing: border-box;
}
.cart-item__remove img{
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.cart-item__image img {border-radius: 3px;max-width: 100%;max-height: 100%;}
.cart-item__image {
    width: 70px;
    height: 70px;
}
.cart-item__name {font-weight: 700;}
.cart-item__size {font-size: 14px;}
.cart-item__remove {position: absolute; top: 0; right: 0;}
.cart-item__price {
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: right;
    font-size: 32px;
    font-weight: 700;
}
.cart-item__quantity span {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 28px;
    font-weight: 700;
}
.cart-item__quantity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}
.btn-minus, .btn-plus {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-item__quantity .btn-plus svg, .cart-item__quantity .btn-minus svg {
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.cart-empty {
    margin: 150px auto;
    margin-bottom: 100px;
    text-align: center;
}

.modal__cart {
    color: #2B3153;
}
.form-cart {
    width: calc(100% - 40px);
    margin: auto;
}
.form-cart .btn {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
/* Product Detail */
.product-detail {
    display: flex;
    width: 992.98px;
}
.product-detail-gallery {
    width: 60%;
    display: flex;
    overflow: hidden;
    position: relative;
}
.product-detail-gallery-wrapper {
    display: flex;
    flex-direction: row;

    position: relative;
    transform: translateX(0%)
}
.product-detail-gallery-item {
    min-width: 100%;
}
.product-detail-gallery-item img {
    width: 100%;
    height: 100%;
}

.product-detail-gallery-thumbnails {
    position: absolute;
    width: 50px;
    top: 0;
    left: 0;
}
.product-detail-gallery-thumb {
    width: 70px;
    height: 70px;
    margin-top: 10px;
    margin-left: 10px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.5);
    opacity: 0.3;
    transition: .3s ;
    cursor: pointer;
}
.product-detail-gallery-thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3000;
}

.product-detail-gallery-thumb.active {
    opacity: 1;
}
.product-modal {
    z-index: 1002;
    color: #2B3153;
}

.product-detail-info {
    padding: 60px 25px;
    width: 40%;
    box-sizing: border-box;
}

.product-detail-info-name {
    font-weight: 700;
    font-size: 32px;
}

.product-detail-info-sizes {
    margin-top: 30px;
}

.product-detail-info-sizes-title .title {
    font-weight: 700;
}
.product-detail-info-sizes-title .caption {
    font-size: 12px;
    padding-left: 10px;
    
}

.product-detail-info-sizes-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-size {
    margin-left: 10px;
    font-size: 16px;
    width: 90px;
    height: 30px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
    transition: .3s;
}
.btn-size:first-child {
    margin: 0;
}
.btn-size.active,.btn-size:hover{
    background-color: #2B3153;
    color: #fff;
}

.product-detail-info-quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    user-select: none;
}
.product-detail-info-quantity span {
    font-size: 36px;
    font-weight: 700;
    margin: 0 20px;
}

.product-detail-info-quantity .btn-minus svg, .product-detail-info-quantity .btn-plus svg{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.product-detail-info__add-to-cart {
    margin-top: 50px;
}

.btn-addtocart span {
    margin-left: 20px;
    transition: .3s;
}
.btn-addtocart .add {
    margin-left: 20px;
    animation: addtocartbtn .4s both ease-in-out;
}
.added {
    animation: addtocartbtn .4s both ease-in-out;
    
}

.modal-inner {
    animation: modal-fadein 0.5s both ease-in;
}


.cart-totals {
    font-size: 32px;
    font-weight: 700;
    text-align: right;
}

.order-created {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 20px;
    font-weight: 700;
    text-align: center;
    min-height: 400px;
}

.form {margin-top: 20px;}

html{scroll-behavior:smooth;}

.square-animation {}

.question-response {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}