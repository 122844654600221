
@media screen and (max-width: 900px) {
    #section-header {
        flex-direction: column-reverse;
        height: auto;
    }
    #section-header .right-side, #section-header .left-side {
        width: 100%;
    }
    
    #section-header .right-side {
        height: 50vh;
    }

    .headline {margin-top: -200px}
    .headline h1, .headline .headline-list {
        color: #fff;
    }
    .headline-count-products__caption {
        font-size: 24px;
    }
}
@media screen and (max-width: 900px) {
    #section-about .about-block {
        flex-direction: column;
    }
    #section-about .about-block-inner {width: 100%;}
}
/* @media screen and (max-width: 1316px) {
    .price {font-size: 20px}
}
@media screen and (max-width: 1190px) {
    .product-info-available {font-size: 12px}
}
@media screen and (max-width: 1140px) {
    .btn__add-to-cart {width: 150px}
} */

@media screen and (max-width: 900px) {
    .products-headline-inner {
        text-align: left;
        padding: 10px;
        box-sizing: border-box;
    }   
    .products-headline-inner h2 {
        font-size: 40px;
    }
    .products-headline-inner .subtitle {
        font-size: 24px;
    }
    .products-headline-inner .subtitle-2 {
        font-size: 16px;
    }
    .products-list {grid-gap: 10px; grid-template-columns: repeat(2, 1fr);}
    /* .old-price {font-size: 10px;font-weight: 400;}
    .price {font-size: 12px;} */
}
@media screen and (max-width: 992.98px) {
    .btn__add-to-cart {width: 90px;font-size: 12px}
    .product-info-right {display: flex;flex-direction: column;text-align: right;}
    .product-info-available {font-size: 10px;opacity: 0.7;width: 50px;height: 50px}
    .btn__add-to-cart span {display: none;}
    .old-price {font-weight: 400;}
    /* .product-notification {} */
}
@media screen and (max-width: 700px) {
    .product-notification-title {font-size: 20px}
    .product-notification .headline-count-products__caption {font-size: 16px;}
    .price {font-size: 20px;}
    .btn__add-to-cart {font-size: 12px;width: 90px;}
    .btn__product-notification {width: calc(100% - 10px);margin-top: 20px;}
    .product-notification .headline-count-products {flex-direction: column;} 
    .product-notification .headline-count-products__count {margin-left: 0; margin-top: 10px}
    .product-notification .headline-count-products__caption {font-size: 16px;}
    .product-notitfication-price {font-size: 16px;}
    .product-notitfication-price span {margin-top: 0px;}
    
}
@media screen and (max-width: 360px) {
    .products-list {grid-template-columns: repeat(1,1fr)}
    .btn__add-to-cart {width: 140px}
}

@media screen and (max-width: 992.98px) {
    .deffect-gallery {
        overflow-x: scroll;
    }
    .deffect-gallery-item {
        width: 90%;
    }
}

@media screen and (max-width: 790px) {
    .question-content {
        flex-direction: column;
    }    
    .questions-popular {
        margin-top: 50px;
    }
}

@keyframes top-to-bottom {
    0% {
        transform: translateY(-100%);
        opacity: 0;

    }
    100% {
        transform: translateY(0);
        opacity: 1;
        box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
    }
}
@media screen and (max-width: 992.98px) {
    .product-detail {
        width: 100%;
        flex-direction: column;
    }
    .product-detail-gallery {
        width: 100vw;
        height: 100vw;
    }
    .product-detail-info {
        width: 100%;
        padding: 20px 10px;
    }
}

@keyframes modal-fadein {
    0% {
        transform: translateY(-200%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes addtocartbtn {
    0% {
        transform: translateY(-200%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes wipe-enter {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
@keyframes wipe-enter-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
@media (prefers-reduced-motion: no-preference) {
    .square-animation-left{
      animation-name: wipe-enter;
      animation-duration: 0.6s;
      animation-iteration-count: both;
    }
    .square-animation-right{
      animation-name: wipe-enter-right;
      animation-duration: 0.7s;
      animation-iteration-count: both;
      
    }
  }
